import APIService from '@/services/APIService.js'

export default {
  getClients () {
    return APIService.apiCall().get('/clients.json')
  },
  postClient (dataClient,idClient=null) {
    const postData = { ...dataClient }
    if(idClient) {
      return APIService.apiCall().post('/clients/edit/'+idClient+'.json',JSON.stringify(postData))
    }
    return APIService.apiCall().post('/clients/add.json',JSON.stringify(postData))
  },
  getClient (id) {
    return APIService.apiCall().get('/clients/view/'+id+'.json')
  },
  deleteClient(id) {
    return APIService.apiCall().delete('/clients/delete/'+id+'.json')
  }
}

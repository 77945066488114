<template>
  <v-container
    id="regular-forms"
    fluid
    tag="section"
  >

    <v-dialog
      v-model="dialog"
    >
      <add-client-from-order ref="clientfromorder" @saved="fillWithClientFromOrder()" />
    </v-dialog>


    <v-row>
      <v-col cols="12">
        <base-material-card
          color="success"
          inline
          title="Ordem"
          class="px-5 py-3"
        >

          <v-form class="pt-1" @submit.prevent="postOrder">

            <v-row class="mt-2">
              <v-col cols="2">
                <v-text-field
                  label="Número da Ordem"
                  outlined
                  filled
                  readonly
                  v-model="order_id"
                />                
              </v-col>
              <v-col cols="5">
                <v-text-field
                  v-model="arrival_showdate"
                  label="Data Chegada"
                  prepend-icon="mdi-calendar"
                  readonly
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  class="mt-1"
                  label="Horário Chegada"
                  prepend-icon="mdi-clock"
                  v-mask="'##:##'"
                  filled
                  readonly
                  v-model="arrival_time"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-text-field
                  label="Técnico Responsável"
                  outlined
                  filled
                  readonly
                  v-model="responsible"
                />                
              </v-col>
            </v-row>


            <v-subheader class="display-1 mt-3 mb-3">
              <b>Cliente</b>
            </v-subheader>

            <v-row>
              <v-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Cliente"
                >
                  <v-autocomplete
                    v-model="client_id"
                    :items="clients"
                    item-value="id"
                    item-text="name"
                    outlined
                    dense
                    label="Cliente *"
                    :error-messages="errors"
                  ></v-autocomplete>

                </validation-provider>
              </v-col>
              <v-col cols="1">
                <v-btn
                  color="green"
                  @click="dialog = true"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="client_whatsapp || client_tel">
              <v-col cols="6">
                <v-text-field
                  label="Whatsapp"
                  outlined
                  filled
                  readonly
                  v-model="client_whatsapp"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Tel"
                  outlined
                  filled
                  readonly
                  v-model="client_tel"
                />
              </v-col>
            </v-row>

            <!--hr>

            <v-row class="mt-2">
              <v-col cols="4">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Serviço"
                >
                  <v-select
                    v-model="service"
                    name="service"
                    label="Serviço"
                    :items="serviceOptions"

                    outlined
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row-->

            <hr>

            <v-subheader class="display-1 mt-3 mb-3">
              <b>Dados do Equipamento</b>
            </v-subheader>

            <!--v-row>
              <v-col cols="2">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Número da Solicitação"
                >
                  <v-autocomplete
                    v-model="service_request_id"
                    :items="service_requests"
                    item-value="id"
                    item-text="id"
                    outlined
                    dense
                    label="Número da Solicitação"
                    :error-messages="errors"
                  ></v-autocomplete>

                </validation-provider>
              </v-col>
            </v-row-->

            <div>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Modelo do Equipamento *"
                    outlined
                    v-model="productmodel"
                  />
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Numero de Série *"
                    outlined
                    v-model="serial_number"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <v-textarea
                    outlined
                    name="input-7-1"
                    label="Acessórios"
                    :value="accessories"
                    hint=""
                    v-model="accessories"
                  />
                </v-col>
                <v-col cols="6">
                  <v-textarea
                    outlined
                    name="input-7-1"
                    label="Detalhes *"
                    :value="details"
                    hint=""
                    v-model="details"
                  />
                </v-col>
              </v-row>
            </div>

            <hr>

            <!--v-subheader class="display-1 mt-3 mb-3">
              <b>Dados dos Produtos</b>
            </v-subheader>

            <v-row  v-for="(product, index) in productList" :key="index" >
              <v-col
                cols="12"
                md="5"
              >
                <v-autocomplete
                  v-model="product.product"
                  :items="products"
                  item-text="name"
                  return-object
                  label="Produto"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="5"
              >
                <v-text-field
                 class="mt-1"
                  label="Quantidade"
                  type="number"
                  v-model="product.product.qty"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-btn
                  color="#2B60D6"
                  class="px-2"
                  min-width="0"
                  outlined
                  fab
                  x-small
                  @click="addProduct"
                >
                  <v-icon
                    small
                  >
                    mdi-plus
                  </v-icon>
                </v-btn>
                <v-btn
                  color="error"
                  class="px-2 ml-2"
                  min-width="0"
                  outlined
                  fab
                  x-small
                  @click="removeProduct(index)"
                >
                  <v-icon
                    small
                  >
                    mdi-minus
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row-->

            <hr>

            <v-subheader class="display-1 mt-3 mb-3">
              <b>Ordem de Serviço</b>
            </v-subheader>

            <div class="ml-n4">
              <v-row>
                <v-col cols="6">
                  <v-textarea
                    outlined
                    name="input-7-1"
                    label="Problema relatado"
                    value=""
                    hint=""
                    class="ml-5"
                    v-model="reported_problem"
                  />
                </v-col>
                <v-col cols="6">
                  <v-textarea
                    outlined
                    name="input-7-1"
                    label="Diagnóstico"
                    value=""
                    hint=""
                    class="ml-5"
                    v-model="diagnosis"
                  />
                </v-col>
              </v-row>
            </div>

            <v-row>
              <v-col md="2" sm="12">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Preço Serviços"
                >
                  <v-text-field
                    label="Preço Serviços *"
                    type="number"
                    class="ml-3"
                    :error-messages="errors"
                    v-model="servicePrice"
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="2" sm="12">
                <v-text-field
                  label="Frete *"
                  type="number"
                  class="ml-3"
                  v-model="shipping"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col md="2" sm="12">

                <v-text-field
                  label="Desconto"
                  type="number"
                  class="ml-3"
                  v-model="discount"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col md="2" sm="12">
                <v-text-field
                  label="Preço Total"
                  type="number"
                  readonly
                  filled
                  class="ml-3"
                  v-model="totalPrice"
                />
              </v-col>
            </v-row>


            <v-row>
              <v-col md="2" sm="12">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Status"
                >
                  <v-select
                    v-model="status"
                    name="status"
                    label="Status *"
                    :items="statusOptions"

                    outlined
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <div v-if="status == 'AUTORIZADO'">
            <v-row>
              <v-col cols="6">
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :return-value.sync="mockDate2"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="show_authorization_date"
                      v-mask="'##/##/####'"
                      @blur="mockDate2 = parseDate(show_authorization_date);"
                      label="Data Autorização"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="mockDate2"
                    @input="
                      menu2 = false; 
                      show_authorization_date = formatDate(mockDate2);
                      updateAuthorizationDate(mockDate2);
                    "
                    locale="pt-br"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="menu2 = false"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu2.save(authorization_date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  class="mt-1"
                  label="Horário Autorização"
                  prepend-icon="mdi-clock"
                  v-mask="'##:##'"
                  v-model="authorization_time"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-menu
                  ref="menu3"
                  v-model="menu3"
                  :close-on-content-click="false"
                  :return-value.sync="mockDate3"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="show_prevision_date"
                      v-mask="'##/##/####'"
                      @blur="mockDate3 = parseDate(show_prevision_date);"
                      label="Data Previsão"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="mockDate3"
                    @input="
                      menu3 = false; 
                      show_prevision_date = formatDate(mockDate3);
                      updatePrevisionDate(mockDate3);
                    "
                    locale="pt-br"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="menu3 = false"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu3.save(prevision_date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  class="mt-1"
                  label="Horário Previsão"
                  prepend-icon="mdi-clock"
                  v-mask="'##:##'"
                  v-model="prevision_time"
                ></v-text-field>
              </v-col>
            </v-row>
            </div>

            <div v-if="status == 'ENTREGUE'">
            <v-row class="mt-2">
              <v-col cols="4">
                <v-select
                  v-model="payment_method"
                  name="service"
                  label="Forma de Pagamento"
                  :items="payment_method_options"
                  outlined
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="mockDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="show_exit_date"
                      v-mask="'##/##/####'"
                      @blur="mockDate = parseDate(show_exit_date);"
                      label="Data Saída"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="mockDate"
                    @input="
                      menu = false; 
                      show_exit_date = formatDate(mockDate);
                      updateExitDate(mockDate);
                    "
                    locale="pt-br"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(exit_date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  class="mt-1"
                  label="Horário Sáida"
                  prepend-icon="mdi-clock"
                  v-mask="'##:##'"
                  v-model="exit_time"
                ></v-text-field>
              </v-col>
            </v-row>
            </div>


            <v-card-actions class="pl-0">
              <v-btn
                color="success"
                min-width="100"
                type="submit"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import OrdersService from '@/services/OrdersService'
  import StocksService from '@/services/StocksService'
  import ClientsService from '@/services/ClientsService'
  import UsersService from '@/services/UsersService'
  import ServiceRequestsService from '@/services/ServiceRequestsService'
  import dayjs from 'dayjs';

  export default {
    name: 'EditOrder',

    components: { 
      AddClientFromOrder: () => import('@/components/AddClientFromOrder'),
    },

    data: () => ({

      dialog: false,

      clients: [],
      client_id: "",

      order_id: '',

      service_requests: [],
      //service_request_id: '',

      responsible: '',

      serial_number: '',
      accessories: '',
      servicePrice: 0,
      shipping: 0,
      discount: 0,
      //description: '',
      //products: [],
      //productList: [],
      status: "",
      statusOptions: ['ORÇAMENTO','AUTORIZADO','AGUARDANDO AUTORIZAÇÃO','AGUARDANDO PEÇA','DEVOLVIDO(NÃO AUTORIZADO)','DEVOLVIDO(NÃO DEU CONSERTO)','DEVOLUÇÃO','ENTREGUE', 'PRONTO','PRONTO(AVISADO)'],
      service: "ORÇAMENTO",
      serviceOptions: ['ORÇAMENTO'],//,'GARANTIA DE MANUTENÇÃO','GARANTIA DE VENDA'],
      payment_method: "",
      payment_method_options: ['Cartão de Débito','Pix','Dinheiro','Depósito','Programado',
        'Cartão de Crédito 1x',
        'Cartão de Crédito 2x',
        'Cartão de Crédito 3x',
        'Cartão de Crédito 4x',
        'Cartão de Crédito 5x',
        'Cartão de Crédito 6x',
        'Cartão de Crédito 7x',
        'Cartão de Crédito 8x',
        'Cartão de Crédito 9x',
        'Cartão de Crédito 10x',
        'Cartão de Crédito 11x',
        'Cartão de Crédito 12x',
      ],
      client_whatsapp: "",
      client_tel: "",

      mockDate: "",
      mockDate2: "",
      mockDate3: "",

      authorization_date: "",
      authorization_time: "",
      arrival_date: "",
      arrival_time: "",
      prevision_date: "",
      prevision_time: "",

      arrival_showdate: "",
      show_authorization_date: "",
      show_exit_date: "",
      show_prevision_date: "",

      productmodel: "",
      details: "",

      reported_problem: "",
      diagnosis: "",

      modal2: false,
      menu: "",
      menu2: "",
      menu3: "",
    }),

    mounted () {

      this.getClients()
      //this.getProducts()
      //this.getRequests()

      if(this.$route.params.id){
        this.getOrder(this.$route.params.id)
        this.order_id = this.$route.params.id
      }else {
        this.getResponsible()
        this.getNowData()
        this.getNextOrderId()
        if(this.$route.params.clientId){
          this.client_id = parseInt(this.$route.params.clientId)
        }
        //this.productList = this.getEmptyProduct()
      }
    },

    computed: {
      
      totalPrice () {
        return (parseFloat(this.servicePrice) + parseFloat(this.shipping)  -  parseFloat(this.discount)).toFixed(2)
      },
    },

    watch: {
      client_id (newValue) {
        var result  = this.clients.filter(function(o){return o.id == newValue} )
        this.client_whatsapp = result[0].whatsapp
        this.client_tel = result[0].tel
      },
      /*service_request_id (newValue) {
        var result  = this.service_requests.filter(function(o){return o.id == newValue;} );
        this.failure = result[0].failure
        this.productmodel = result[0].productmodel
      },*/
    },

    methods: {

      fillWithClientFromOrder() {
        this.dialog = false
        var element = this.$refs.clientfromorder.saved

        var obj = {
          id: element.id,
          name: element.name,
          whatsapp: element.whatsapp,
          tel: element.tel
        }
        this.clients.push(obj);

        this.client_id = this.$refs.clientfromorder.saved.id
      },

      updateAuthorizationDate (valor) {
        this.authorization_date = valor
        this.$forceUpdate()
      },

      updateExitDate (valor) {
        this.exit_date = valor
        this.$forceUpdate()
      },

      updatePrevisionDate (valor) {
        this.prevision_date = valor
        this.$forceUpdate()
      },

      formatDate (date) {
        return dayjs(date).format('DD/MM/YYYY')
      },

      parseDate (date) {
        if (!date) return null

        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },

      getNowData() {

        const today = new Date();
        const date = today.getFullYear()+'-'+(today.getMonth()+1 < 10 ? '0'+(today.getMonth()+1) : today.getMonth()+1)+'-'+(today.getDate() < 10 ? '0'+(today.getDate()) : today.getDate());
        const showdate = (today.getDate() < 10 ? '0'+(today.getDate()) : today.getDate())+'-'+(today.getMonth()+1 < 10 ? '0'+(today.getMonth()+1) : today.getMonth()+1)+'-'+today.getFullYear();
        const time = (today.getHours() < 10 ? '0'+(today.getHours()) : today.getHours()) + ":" + (today.getMinutes() < 10 ? '0'+(today.getMinutes()) : today.getMinutes());
        //const dateTime = date +' '+ time;

        this.arrival_showdate = showdate;

        this.arrival_date = date
        this.arrival_time = time
      },

      getResponsible() {
        UsersService.getLoggedUser()
        .then((response) => {
          if(response.data.message == "Error"){
            throw 500
          }
          this.responsible = response.data.user.name
        })
        .catch(error => {
          this.$toast.error('Erro ao recuperar usuário logado, recarregue a página.', '',{position:'topRight'})
        })
      },

      getNextOrderId() {
        OrdersService.getNextOrderId()
        .then((response) => {
          if(response.data.message == "Error"){
            throw 500
          }
          //console.log(response.data)
          this.order_id = response.data.id
        })
        .catch(error => {
          this.$toast.error('Erro ao recuperar número da ordem, recarregue a página.', '',{position:'topRight'})
        })
      },

      /*addProduct () {
        let obj = {
          product: {
            name: "",
            price: 0,
            qty: 0
          },
        }
        this.productList.push(obj)
      },

      removeProduct (index) {
        if (index == 0) {
          this.productList = this.getEmptyProduct();
        } else {
          this.productList.splice(index,1)
        }
      },

      getEmptyProduct () {

        return [
          {
            product: {
              name: "",
              price: 0,
              qty: 0
            },
          }
        ]

      },*/

      postOrder() {
        let data = []

        data['client_id']           =   this.client_id
        //data['products']            =   this.productList
        data['status']              =   this.status
        //data['description']         =   this.description
        data['service_price']       =   this.servicePrice
        data['shipping'] = this.shipping
        data['discount']            =   this.discount
        data['authorization_date']   =   this.authorization_date,
        data['authorization_time']      =   this.authorization_time,
        data['arrival_date']        =   this.arrival_date,
        data['arrival_time']        =   this.arrival_time,
        data['exit_date']        =   this.exit_date,
        data['exit_time']        =   this.exit_time,
        data['prevision_date']        =   this.prevision_date,
        data['prevision_time']        =   this.prevision_time,
        data['payment_method']      =   this.payment_method,
        data['serial_number']       =   this.serial_number,
        data['productmodel']       =   this.productmodel,
        data['details']             =   this.details,
        data['accessories']         =   this.accessories,
        data['reported_problem'] = this.reported_problem,
        data['diagnosis'] = this.diagnosis,
        //data['service_request_id']  =   this.service_request_id,

        console.log(data)
        

        this.$toast.info('Salvando Ordem.', '',{position:'topRight'})

        OrdersService.postOrder(data,this.$route.params.id)
        .then((response) => {
          if(response.data.message == "Error"){
            console.log(response.data)
            throw 500
          }
          this.$toast.success('Ordem Salva Sucesso.', '',{position:'topRight'})
          this.$router.push({ name: 'Ordens' })
        })
        .catch(error => {
          this.$toast.error('Erro ao salvar.', '',{position:'topRight'})
        })
      },

      getOrder(id) {
        OrdersService.getOrder(id)
        .then(response => {
          //console.log(response.data)
          this.client_id          = response.data.order.client_id
          this.status             = response.data.order.status
          this.responsible        = response.data.order.responsible.name
          this.servicePrice       = response.data.order.service_price
          this.shipping = response.data.order.shipping
          this.discount           = response.data.order.discount

          this.mockDate           = response.data.order.exit_date,
          this.mockDate2          = response.data.order.authorization_date,
          this.mockDate3          = response.data.order.prevision_date,

          this.show_authorization_date = this.formatDate(response.data.order.authorization_date),
          this.show_exit_date = this.formatDate(response.data.order.exit_date),
          this.show_prevision_date = this.formatDate(response.data.order.prevision_date),

          this.exit_date          = response.data.order.exit_date,
          this.authorization_date  = response.data.order.authorization_date,
          this.prevision_date  = response.data.order.prevision_date,
          
          this.authorization_time     = response.data.order.authorization_time,
          this.exit_time          = response.data.order.exit_time,
          this.prevision_time          = response.data.order.prevision_time,

          this.arrival_date       = response.data.order.arrival_date,
          this.arrival_time       = response.data.order.arrival_time,
          this.arrival_showdate = this.formatDate(this.arrival_date),

          this.payment_method     = response.data.order.payment_method,
          this.serial_number      = response.data.order.serial_number,
          this.productmodel      = response.data.order.productmodel,
          this.details            = response.data.order.details,
          this.accessories        = response.data.order.accessories,
          this.diagnosis          = response.data.order.diagnosis,
          this.reported_problem = response.data.order.reported_problem
          //this.service_request_id = response.data.order.service_request_id

          //this.createProductList(response.data.order)
        })
        .catch(error => {
          this.$toast.error('Erro ao recuperar ordem.', '',{position:'topRight'})
        })
      },

      /*createProductList(order) {
        order.order_products.forEach(element => {
          let obj = {
            product: order.products.find(x => x.id === element.product_id),
          }
          obj.product.qty = element.qty
          this.productList.push(obj)
        });
      },*/

      async getClients() {
        await ClientsService.getClients()
        .then(response => {
          this.clients = []
          let obj = {}
          response.data.clients.forEach(element => {

            obj = {
              id: element.id,
              name: element.name,
              whatsapp: element.whatsapp,
              tel: element.tel
            }
            this.clients.push(obj);
          });
          this.$forceUpdate()
        })
        .catch(error => {
          this.$toast.error('Erro ao recuperar clientes, recarregue a página.', '',{position:'topRight'})
        })
      },
      /*getRequests () {
        ServiceRequestsService.getServicesRequests()
        .then(response => {
          this.items = []
          let obj = {}
          response.data.service_requests.forEach(element => {

            obj = {
              id: element.id,
              productmodel: element.productmodel,
              failure: element.failure,
            }
            this.service_requests.push(obj);
          });
        })
        .catch(error => {
          this.$toast.error('Erro ao recuperar solicitações de serviço, recarregue a página.', '',{position:'topRight'})
        })
      },*/
      /*getProducts() {
        StocksService.getProducts()
        .then(response => {
          this.products = []
          let obj = {}
          response.data.products.forEach(element => {
            obj = {
              id: element.id,
              name: element.name,
              price:  parseFloat(element.price)
            }
            this.products.push(obj);
          });
        })
        .catch(error => {
          this.$toast.error('Erro ao recuperar produtos, recarregue a página.', '',{position:'topRight'})
        })
      }*/
    },
  }
</script>
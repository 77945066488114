import APIService from '@/services/APIService.js'

export default {
  getProducts () {
    return APIService.apiCall().get('/products.json')
  },
  postProduct (dataProduct,idProduct=null) {
    const postData = { ...dataProduct }
    if(idProduct) {
      return APIService.apiCall().post('/products/edit/'+idProduct+'.json',JSON.stringify(postData))
    }
    return APIService.apiCall().post('/products/add.json',JSON.stringify(postData))
  },
  getProduct (id) {
    return APIService.apiCall().get('/products/view/'+id+'.json')
  },
  deleteProduct(id) {
    return APIService.apiCall().delete('/products/delete/'+id+'.json')
  }
}
